import React from "react";
import './About.css';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

function About() {

    const {t} = useTranslation()

    return (
        <>
            <Helmet>
                <title>Polaris Synergies - {t('About us')}</title>
                <meta name="description"
                      content={t('Polaris Synergies answers the needs of companies for their strategic evolutions - M&As, turn-arounds… - and their operational performance - project & contract management, release of bank guarantees, interim management…-. We propose lump sum services with well defined deliverables, and a demonstrated ROI. Patrick Berthier is the Managing Director, 20 years of experience in general management.')}/>
            </Helmet>
            <div className="about">
                <div className="about-left">
                    <h1 className="display-6 text-center">{t('About us')}</h1>
                    <h2 className="display-6">{t('Our mission')}</h2>
                    <div className="raison-d-etre">
                        <p className="lead">
                            {t('Polaris Synergies answers the needs of companies for:')}
                            <li>
                                {t('Their strategic evolutions: Mergers & Acquisitions, company turn-around or restructuring, business intelligence, streamlining of legal entities, technology transfers')}
                            </li>
                            <li>
                                {t('Their operational performance: project and contract management, expert witness services – technical, quantum and delay - , release of bank guarantees, cash-flow management, integrated full costs')}

                            </li>
                            {t('We also propose interim management in those two frameworks.')}
                        </p>
                    </div>

                    <h2 className="display-6">{t('Our objective')}</h2>
                    <div className="objectif">
                        <p className="lead">
                            {t('Our objective is always value creation for our customer.')}
                            <br/>
                            {t('We propose most of the time lump sum services with well defined deliverables, and a demonstrated Return On Investment for our customer.')}
                        </p>
                    </div>

                    <h2 className="display-6">{t('The Polaris Synergies team')}</h2>
                    <div className="equipe">
                        <p className="lead">
                            <b>Patrick Berthier:</b>
                            <li>
                                {t('Managing Director, 20 years of international experience in general management, project & contract management in energy & industry sectors.')}
                            </li>
                            <li>
                                {t('He works since 6 years in Mergers & Acquisitions for acquirers as well as for vendors')}
                            </li>
                            <b>{t('The Polaris Synergies network')}:</b>
                            <li>
                                {t('Depending on missions’ sizes Polaris Synergies brings in its network of partners in the fields of Legal, Finance, M&A, Contracts…')}
                            </li>
                            <li>
                                {t('Those partners support the execution of the relevant services')}
                            </li>
                        </p>
                    </div>
                </div>

                <div className="about-right"/>
            </div>
        </>
    );
}

export default About;
