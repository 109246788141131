import React from "react";
import './References.css';
import logoSulzer from '../assets/logo-sulzer.webp';
import logoEnsivalMoret from '../assets/logo-ensival-moret.webp';
import logoSolix from '../assets/solix-logo.svg';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

function References() {

    const {t} = useTranslation()

    return (
        <>
            <Helmet>
                <title>Polaris Synergies - {t('Sectors of activity & references')}</title>
                <meta name="description"
                      content={t('Polaris Synergies’ main sectors of activity: industry (Sulzer, Ensival Moret…), family owned-companies, Small and Medium Enterprises.')}/>
            </Helmet>
            <div className="references">
                <div className="references-left">
                    <h1 className="display-5 text-center">{t('Sectors of activity & references')}</h1>
                    <br/>
                    <br/>
                    <h2 className="display-6">{t('Main sectors of activity')}:</h2>
                    <ul className="list-group list-group-flush mb-5">
                        <li className="list-group-item lead industrie">
                            {t('Industry: energy, turbines, pumps, nuclear, depollution')}
                        </li>
                        <li className="list-group-item lead type-entreprise">
                            {t('Family owned companies, Small and Medium Enterprises (SMEs)')}
                        </li>
                        <li className="list-group-item lead investment-funds">
                            {t('Investment funds')}
                        </li>
                    </ul>
                    <h2 className="display-6 mb-3">{t('Our main customer references')}:</h2>
                    <div className="logos">
                        <img src={logoSulzer} alt="Sulzer"></img>
                        <img src={logoEnsivalMoret} alt="Ensival Moret"></img>
                        <img src={logoSolix} alt="Solix"></img>
                    </div>
                </div>
                <div className="references-right">

                </div>
            </div>
        </>
    );
}

export default References;
